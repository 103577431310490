<template>
  <v-row no-gutters>
    <div class="mr-2">
      <defaultFieldTypes
        :fieldAttributes="result.fields['n_mcc']"
        field="n_mcc"
        :templateContent="result"
      :value="result.valueLine.n_mcc"></defaultFieldTypes>
    </div>
    <defaultFieldTypes
      :fieldAttributes="result.fields['n_mnc']"
      field="n_mnc"
      :templateContent="result"
    :value="result.valueLine.n_mnc"></defaultFieldTypes>
  </v-row>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  props: ["result"],
  components: { defaultFieldTypes },
};
</script>